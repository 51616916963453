
.view {
	margin: auto;
   
}

.wrapper {
	position: relative;
	overflow: auto;
   	/* border: 1px solid black; */
  	white-space: nowrap;
}

.sticky-col {
    position: sticky;
    position: -webkit-sticky;    
    background-color: white;
}
  
.first-col {
	width: 320px;
    min-width: 250px;
    max-width: 285px;
    left: 0px; 
     z-index: 5;
}

/* .second-col {
	width: 150px;
    min-width: 150px;
    max-width: 150px;
	left: 100px;    
} */
/* .total-col {
width: 150px;
 min-width: 150px;
    max-width: 150px;
    right: 0;
    
} */
.input_edit {
    text-align: center;
    border: none;
    background: transparent;
    width: 100%;
    
} 
#gradebook_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
 border: 1px solid #eff2f7;

  color: black;
}
/* #gradebook_table tr:nth-child(even){background-color: #f2f2f2;} */

 #gradebook_table td,  th {
  border:  1px solid #eff2f7;
  padding: 8px;
} 
.controlled_td {
  min-width: 150px;
    max-width: 150px;
}

.title_assignment {
  display: block;
  width: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.dropdownw_headers {
 right: 0;
}

/* /**** to fix table header  */
 /* .header-fixed {
    width: 100% 
}

.header-fixed > thead,
.header-fixed > tbody,
.header-fixed > thead > tr,
.header-fixed > tbody > tr,
.header-fixed > thead > tr > th,
.header-fixed > tbody > tr > td {
    display: block;
}

.header-fixed > tbody > tr:after,
.header-fixed > thead > tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
}

.header-fixed > tbody {
    overflow-y: auto;
    height: 500px;
}

.header-fixed > tbody > tr > td,
.header-fixed > thead > tr > th {
    width: 20%;
    float: left;
}  */


/* to sticky table header ends */