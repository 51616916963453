/* .tg  {border-collapse:collapse;border-spacing:0;margin:0px auto;}
.tg td{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:10px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:black;border-style:solid;border-width:1px;font-family:Arial, sans-serif;font-size:10px;
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .Cabezal
{border-color:inherit;
    text-align:center;
    vertical-align:middle; 
    font-weight: bold;
    padding:0;
 }
.tg .juntas{border-color:#000000;text-align:center;vertical-align:top }
.tg .solas{border-color:inherit;text-align:left;vertical-align:bottom; font-weight: bold; padding-bottom: 4px; padding-top: 20px;}
.tg .solast{border-color:inherit;text-align:left;vertical-align:bottom; font-weight: bold; padding-bottom: 4px; padding-top: 4px;}
.tg .Notas{border-color:inherit;text-align:center;vertical-align:top; font-size:9px;}
.tg .subject{border-color:inherit;text-align:center;vertical-align:top; font-weight: bold; }
.tg .Condition{text-align:center;vertical-align:top} */

.profile{
    border-radius: 50%;

}
/* .subject{

    text-align: center;
    font-weight: bold;
    background-color: #f1c40f;
    width:40%;
} */
.table-attendance{
 margin-top: 25px;
    border: 0.1mm solid #000000;
  border-collapse:collapse;

}
.table-attendance  td,th {
 border: 0.1mm solid #000000;
  border-collapse:collapse;

}
.table-condition{
 margin-top: 25px;
    border: 0.1mm solid #000000;
  border-collapse:collapse;

}
.table-condition  td,th {
 border: 0.1mm solid #000000;
  border-collapse:collapse;

}
.table-leyenda{
    margin-top: 130px;
    border: 0.1mm solid #000000;
  border-collapse:collapse;
}
.table-leyenda td,th {
  border: 0.1mm solid #000000;
  border-collapse:collapse;

}
.beta td,th{

    border: 0.1mm solid #000000;
  border-collapse:collapse;
}
.cabezal{
    font-family: verdana;
font-size: 12pt;
font-weight:bold;
 text-align:left;
}

.names{
     font-family: arial;
font-size: 12pt;
font-weight:bold;
 text-align:left;
}
.quemao {

padding-top:20px;
font-size:9pt;
}
.quemao td,th{

    border: 0.1mm solid #000000;
  border-collapse:collapse;
}

.titulotable-leyenda{
    text-align:center;
    font-weight:bold;
    padding:3px;

}
.cuerpotable-leyenda{
    text-align:center;
    padding:3px;
}

.firma{
   
  float: left;
    border-bottom:   0.1mm solid #000000;
   width:  200px;
    height: 80px;
 object-fit: cover;
}
.firmas{
padding-top:30px;

}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.col {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

.notas.honores {
  text-align: center;
  /* Centrado horizontal */
  vertical-align: middle;
  /* Centrado vertical */
  background-color: #f2f2f2;
  /* Fondo gris suave */
  padding: 10px;
  /* Espaciado interno para mejorar la apariencia */
}

.bulletin .table td {
   text-align: center;   
}
@media all {
.page-break { display: none; }
}

@media print {
.page-break { display: block; page-break-before: always; }
}
.line-top
{     
     text-decoration:overline;
     color : black;
}
@page {
   size: landscape;
   margin: 20%
}
@page {
  size: 3in 5in;
}