.profile{
    border-radius: 50%;
}
.table-border td,th{

    border: 0.1mm solid #000000;
  border-collapse:collapse;
}.gama {
    margin:15px 0px 15px 0;
}
.gama td,th{

    border: 0.1mm solid #000000;
  border-collapse:collapse;
}
.subject{

    text-align: center;
    font-weight: bold;
    color:black;
    background-color: #80C535;
    padding:10px;
    font-size: 14px;
}
.escala{
    margin-top: 25px;
     border: 0.1mm solid #000000;
  border-collapse:collapse;
}
.escala td,th {
border: 0.1mm solid #000000;
  border-collapse:collapse;
}
.rallitas tr:nth-child(even) {
  background-color: #f1f2f6;
}
.noborders > td{
       border-color: transparent;
    border-bottom-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
}
.cabezal{
    font-family: verdana;
font-size: 12pt;
font-weight:bold;
 text-align:left;
}

.names{
     font-family: arial;
font-size: 12pt;
font-weight:bold;
 text-align:left;
}

.competencias{
font-size: 10pt;
padding-left: 4px;
}
.firma{
   float: left;
    border-bottom:   0.1mm solid #000000;
   width:  140px;
    height: 65px;
 /*object-fit: cover;
*/
}
.firmafoto{

  width:  140px;
    height: 65px;
}
.firmas{
padding-top:60px;

}