.profile{
    border-radius: 50%;

}
/* .subject{

    text-align: center;
    font-weight: bold;
    background-color: #f1c40f;
    width:40%;
} */
.leyenda{
    margin-top: 25px;
    border: 0.1mm solid #000000;
  border-collapse:collapse;
}
.leyenda td,th {
  border: 0.1mm solid #000000;
  border-collapse:collapse;

}
.beta td,th{

    border: 0.1mm solid #000000;
  border-collapse:collapse;
}
.cabezal{
    font-family: verdana;
font-size: 12pt;
font-weight:bold;
 text-align:left;
}

.names{
     font-family: arial;
font-size: 12pt;
font-weight:bold;
 text-align:left;
}
.quemao {

padding-top:20px;
font-size:9pt;
}
.quemao td,th{

    border: 0.1mm solid #000000;
  border-collapse:collapse;
}

.tituloleyenda{
    text-align:center;
    font-weight:bold;
    padding:3px;

}
.cuerpoleyenda{
    text-align:center;
    padding:3px;
}

.firma{
   
  float: left;
    border-bottom:   0.1mm solid #000000;
   width:  200px;
    height: 80px;
 object-fit: cover;
}
.firmas{
padding-top:30px;

}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.notas.honores {
  text-align: center;
  /* Centrado horizontal */
  vertical-align: middle;
  /* Centrado vertical */
  background-color: #f2f2f2;
  /* Fondo gris suave */
  padding: 10px;
  /* Espaciado interno para mejorar la apariencia */
}

.col {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}
.bulletin .table td {
   text-align: center;   
}
@media all {
.page-break { display: none; }
}

@media print {
.page-break { display: block; page-break-before: always; }
}
.line-top
{     
     text-decoration:overline;
     color : black;
}
@page {
   size: landscape;
   margin: 20%
}
@page {
  size: 3in 5in;
}